<template>
  <div>
    <div class="card-toolbar mb-5">
      <button v-if="$can('key_generations.create')" @click="showModal" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('key_generation.add_key_generation') }}
      </button>
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6 mb-2 mt-2">
              <label for="f_name">{{ $t('name') }}</label>
              <input v-model="filters.name" type="text" id="f_name" class="form-control">
            </div>
            <div class="form-group col-md-6 mb-2 mt-2">
              <label for="f_identity_no">{{ $t('key_generation.identity_no') }}</label>
              <input v-model="filters.identity_no" type="text" id="f_identity_no" class="form-control">
            </div>
            <div class="form-group col-md-6 mb-2 mt-2">
              <label for="name">{{ $t('status') }}</label>
              <select name="" id="is_active" v-model="filters.is_active" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
              </select>
            </div>
            <div class="form-group d-inline-flex col-md-6 mt-10">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                <span><i class="fas fa-search"></i>
                                    <span>{{ $t('search') }}</span>
                                </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                                <span><i class="fas fa-trash-restore"></i>
                                    <span>{{ $t('reset_search') }}</span>
                                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="status" slot-scope="props">
            <b-form-checkbox v-if="$can('key_generations.change_status')"
                             size="lg" @change="changeStatus(props.row.id, props.row.is_active)"
                             v-model="props.row.is_active"
                             :name="'check-button'+props.row.id"
                             switch :key="props.row.id">
            </b-form-checkbox>
            <b-form-checkbox v-else
                             size="lg" :disabled="true"
                             v-model="props.row.is_active"
                             :name="'check-button'+props.row.id"
                             switch :key="props.row.id">
            </b-form-checkbox>
          </template>
          <template slot="actions" slot-scope="props">
            <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('key_generations.update')" @click="showModal(props.row)">mdi-pencil</v-icon>
            <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('key_generations.delete')" @click="deletekey_generation(props.row)">mdi-delete</v-icon>
          </template>
        </v-server-table>
        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->

    <b-modal ref="modal" hide-footer :title="$t('key_generation.key_generation')">
      <div class="row">
        <div class="col-lg-6 mb-5">
          <label for="name">
            {{ $t('key_generation.name') }}<span class="text-danger">*</span>
          </label>
          <input v-model="key_generation.name" type="text" id="name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''">
          <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.name[0] }}
                </span>
        </div>
        <div class="col-lg-6 mb-5">
          <label for="name">
            {{ $t('key_generation.identity_no') }}
          </label>
          <input v-model="key_generation.identity_no" disabled type="text" id="identity_no" class="form-control">

        </div>
        <div class="col-lg-6 mb-5">
          <label for="name">
            {{ $t('key_generation.key') }}
          </label>
          <input v-model="key_generation.key" disabled type="text" id="key" class="form-control">
        </div>
        <div class="col-lg-6 mb-5">
          <label>{{ $t('status') }}:</label>
          <b-form-checkbox size="lg" v-model="key_generation.is_active" name="check-button" switch></b-form-checkbox>
        </div>
      </div>


      <div class="d-flex justify-content-end">
        <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{ $t('close') }}</b-button>
        <b-button class="mt-2 mr-1" variant="primary" @click="save">{{ $t('save') }}</b-button>
      </div>
    </b-modal>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index",
  components: {},
  data() {
    return {
      key_generation: {
        name: '',
        identity_no: '',
        is_active: true,
      },
      showAdvancedSearch: false,
      filter: {
        sortBy: 'id',
      },
      filters: {
        name: '',
        identity_no: '',
        is_active: '',
      },
      columns: ['name', 'identity_no', 'created_at', 'key', 'status', 'actions'],
      validation: null,
      mainRoute: 'settings/key_generations',
      routeChangeStatus: 'settings/key_generation/change-status',
      isEditing: false,
      id: null,
      status_list: [
        {id: 1, text: this.$t('active')},
        {id: 0, text: this.$t('inactive')},
      ],
    }
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          name: that.$t('key_generation.name'),
          identity_no: that.$t('key_generation.identity_no'),
          created_at: that.$t('created_at'),
          key: that.$t('key_generation.key'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',
        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.key_generations_management")}]);
  },
  methods: {
    changeStatus(id, status) {
      ApiService.patch(this.routeChangeStatus + '/' + id, {
        is_active: (status ? 1 : 0),
      }).then((response) => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.$errorAlert(error);
      });
    },

    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.name = '';
      this.filters.identity_no = '';
      this.filters.is_active = '';
      this.$refs.table.refresh();
    },
    save() {
      if (this.isEditing) {
        this.updatekey_generation();
      } else {
        this.savekey_generation();
      }
    },
    getFetch() {
      this.$refs.table.refresh();
    },
    actionDelete(key_generation) {
      ApiService.delete(`${this.mainRoute}/${key_generation.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    deletekey_generation(key_generation) {
      this.$confirmAlert('', this.actionDelete, key_generation);
    },

    showModal(data) {
      this.reset();
      this.validation = null;
      if (!data.id) {
        this.isEditing = false;
        this.id = null;
        this.$refs['modal'].show()
      } else {
        this.isEditing = true;
        this.id = data.id;
        this.getData(data);
        this.$refs['modal'].show();
      }
    },
    hideModal() {
      this.reset();
      this.$refs['modal'].hide()
    },
    toggleModal() {
      this.$refs['modal'].toggle('#toggle-btn')
    },
    savekey_generation() {
      ApiService.post(this.mainRoute, this.key_generation).then((response) => {
        this.$successAlert(response.data.message);
        this.afterSavekey_generation();
      }).catch((errors) => {
        this.validation = errors.response ? errors.response.data.errors : null;
      });
    },
    updatekey_generation() {
      ApiService.put(`${this.mainRoute}/${this.id}`, {
        ...this.key_generation,
      }).then((response) => {
        this.$successAlert(response.data.message);
        this.validation = null;
        this.afterSavekey_generation();
      })
          .catch((errors) => {
            this.validation = errors.response ? errors.response.data.errors : null;
          });
    },
    getData(data) {
      this.key_generation.name = data.name;
      this.key_generation.key = data.key;
      this.key_generation.identity_no = data.identity_no;
      this.key_generation.is_active = data.is_active;
    },

    reset() {
      this.key_generation = {
        name: '',
        identity_no: '',
        is_active: true
      };
      this.validation = [];
    },

    afterSavekey_generation() {
      this.key_generation = {
        name: '',
        identity_no: '',
        is_active: true
      };
      this.hideModal();
      this.getFetch();
    }
  },
};
</script>
